<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <section>
      <label class="block relative">
        <span class="text-gray-800 text-sm">Your API endpoint for accepting requests</span>
        <input
          v-model="$v.formResponses.endpoint.$model"
          type="text"
          class="form-input mt-1 w-full"
          placeholder="https://api.example.com/webhook-endpoint"
        >
      </label>
      <ErrorsInline v-if="errors">
        <span v-if="!$v.formResponses.endpoint.url">This must be a URL.</span>
      </ErrorsInline>
    </section>

    <section class="mx-auto text-center">
      <BaseButton
        class="mt-8"
        :loading="loading"
        @click="submit"
      >
        Save changes
      </BaseButton>

      <div class="mt-2 flex justify-center">
        <ErrorsInline
          v-if="empty && uiState === 'submit clicked'"
          class="text-center"
        >
          Please fill in the url fields above
        </ErrorsInline>
      </div>
    </section>
  </form>
</template>

<script>
import ErrorsInline from '@components/ErrorsInline'

import { validationMixin } from 'vuelidate'
import { url } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    ErrorsInline
  },

  mixins: [validationMixin],

  data() {
    return {
      uiState: 'submit not clicked',
      loading: false,
      errors: false,
      empty: true,

      formResponses: {
        endpoint: null
      }
    }
  },

  computed: {
    ...mapGetters({
      webhookEndpoint: 'organisations/webhookEndpoint'
    })
  },

  validations: {
    formResponses: {
      endpoint: {
        url
      }
    }
  },

  mounted() {
    this.formResponses.endpoint = this.webhookEndpoint
  },

  methods: {
    submit() {
      this.empty = !this.$v.formResponses.$anyDirty
      this.errors = this.$v.formResponses.$anyError
      this.uiState = 'submit clicked'

      if (this.errors === false && this.empty === false) {
        this.loading = true

        this.$store.dispatch('organisations/updateWebhookEndpoint', {
          endpoint: this.formResponses.endpoint
        })
          .then(() => {
            this.$emit('close')
            this.uiState = 'form submitted'
          })
          .catch(() => {
            this.uiState = 'submit not clicked'
          })
      }
    }
  }
}
</script>
