<template>
  <BaseContainer>
    <WebhooksSettings />
  </BaseContainer>
</template>

<script>
import WebhooksSettings from '@components/Settings/WebhooksSettings'

export default {
  components: {
    WebhooksSettings
  },

  page() {
    return {
      title: 'Webhooks'
    }
  }
}
</script>
