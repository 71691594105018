<template>
  <table>
    <thead>
      <tr>
        <th>Field</th>
        <th>Type</th>
        <th>Description</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(attribute, i) in attributes"
        :key="i"
      >
        <th>{{ attribute.attribute }}</th>
        <td><kbd>{{ attribute.type }}</kbd></td>
        <td>{{ attribute.description }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    attributes: {
      type: Array,
      required: true
    }
  }
}
</script>
