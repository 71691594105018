<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <Terminal />
          </Icon>
        </span>
        <h2 class="text-lg">
          Webhooks
        </h2>
      </div>
    </header>
    <div class="prose mt-4">
      <p>
        A webhook is a request that’s triggered after an event occurs.
      </p>
      <p>
        We provide a webhook that can send a request to your endpoint for the following events:
      </p>
      <ul>
        <li>When a new candidate sign ups</li>
        <li>When a candidate completes a test</li>
      </ul>
      <p>More webhook events will be added in the future.</p>
    </div>
  </Basecard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Terminal from '@components/Icons/Terminal'

export default {
  components: {
    Icon,
    Terminal
  }
}
</script>
