<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <ClipboardCheck />
          </Icon>
        </span>
        <h2 class="text-lg">
          Webhook settings
        </h2>
      </div>

      <EditWebhookButton />
    </header>

    <section>
      <dl class="flex flex-wrap mt-8 text-sm">
        <dt class="w-1/3 py-4 text-gray-500">
          Endpoint
        </dt>
        <dd class="w-2/3 py-4">
          <template v-if="webhookEndpoint">
            {{ webhookEndpoint }}
          </template>
          <template v-else>
            <!-- empty -->
          </template>
        </dd>
      </dl>
    </section>
  </Basecard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import ClipboardCheck from '@components/Icons/ClipboardCheck'
import EditWebhookButton from '@components/Settings/Webhooks/EditWebhookButton'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    ClipboardCheck,
    EditWebhookButton
  },

  computed: {
    ...mapGetters({
      webhookEndpoint: 'organisations/webhookEndpoint'
    })
  }
}
</script>
