<template>
  <BaseCard>
    <header class="sm:flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            icon="Team"
            width="24px"
            height="24px"
            view-box="0 0 80.13 80.13"
          >
            <Team />
          </Icon>
        </span>
        <h2 class="text-lg">
          Guide
        </h2>
      </div>
    </header>

    <div class="prose mt-4">
      <h3>Example body</h3>
      <p>
        All webhook bodies have the same format with two main keys.
      </p>
      <pre>{{ exampleBody }}</pre>
      <p>
        The requests are sent as <kbd>POST</kbd> requests.
      </p>
      <h3>New candidate</h3>
      <p>When a candidate succesfully signs up, this event is triggered.</p>
      <GuideCardAttributesTable
        :attributes="candidateCreatedAttributes"
      />
      <pre>{{ exampleCandidateCreated }}</pre>
      <h3>New test completed</h3>
      <p>When a candidate succesfully finishes a test, this event is triggered.</p>
      <GuideCardAttributesTable
        :attributes="testCompletedAttributes"
      />
      <pre>{{ exampleTestCompleted }}</pre>
    </div>
  </BaseCard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Team from '@components/Icons/Team'
import GuideCardAttributesTable from '@components/Settings/Webhooks/GuideCardAttributesTable'

const exampleBody = {
  event: 'name',
  data: {}
}

const exampleCandidateCreated = {
  event: 'candidateCreated',
  data: {
    uuid: '123e4567-e89b-12d3-a456-426614174000',
    email: 'hannah@example.com',
    firstName: 'Hannah',
    surname: 'Williams',
    createdAt: 1626261567
  }
}

const candidateCreatedAttributes = [
  {
    attribute: 'uuid',
    type: 'string',
    description: 'The UUID of the candidate'
  },
  {
    attribute: 'email',
    type: 'string',
    description: 'The email address of the candidate'
  },
  {
    attribute: 'firstName',
    type: 'string',
    description: 'The first name of the candidate'
  },
  {
    attribute: 'surname',
    type: 'string',
    description: 'The surname of the candidate'
  },
  {
    attribute: 'completedAt',
    type: 'integer',
    description: 'UNIX timestamp'
  }
]

const exampleTestCompleted = {
  event: 'testCompleted',
  data: {
    candidateUuid: '123e4567-e89b-12d3-a456-426614174000',
    testSlug: 'numerical-reasoning',
    completedAt: 1626261569,
    scorePercentage: 67,
    speedCompleted: 366,
    questionsCorrect: 14,
    questionsIncorrect: 5,
    questionsTotal: 20,
    result: null,
    scores: null
  }
}

const testCompletedAttributes = [
  {
    attribute: 'candidateUuid',
    type: 'string',
    description: 'The UUID of the candidate'
  },
  {
    attribute: 'testSlug',
    type: 'string',
    description: 'Identifier for the test taken'
  },
  {
    attribute: 'completedAt',
    type: 'integer',
    description: 'UNIX timestamp'
  },
  {
    attribute: 'scorePercentage',
    type: 'integer',
    description: 'Percentage from 0 to 100'
  },
  {
    attribute: 'speedCompleted',
    type: 'integer',
    description: 'Seconds the test was completed in'
  },
  {
    attribute: 'questionsCorrect',
    type: 'integer',
    description: 'Number of correctly answered questions'
  },
  {
    attribute: 'questionsIncorrect',
    type: 'integer',
    description: 'Number of incorrectly answered questions'
  },
  {
    attribute: 'questionsTotal',
    type: 'integer',
    description: 'Number of questions in the test when taken'
  },
  {
    attribute: 'result',
    type: 'string (null)',
    description: 'A JSON object with result, for non-score based tests'
  },
  {
    attribute: 'score',
    type: 'string (null)',
    description: 'A JSON object with score data, for non-numerically score based tests'
  }
]

export default {
  components: {
    Icon,
    Team,
    GuideCardAttributesTable
  },

  data() {
    return {
      exampleBody,
      exampleCandidateCreated,
      candidateCreatedAttributes,
      exampleTestCompleted,
      testCompletedAttributes
    }
  }
}
</script>
